body {
	overflow: auto;
	padding: 0;
	margin: 0;
}
header {
	padding: 0 !important;
}
.react-grid-layout {
	position: relative;
	transition: height 200ms ease;
}

.react-grid-item {
	transition: all 200ms ease;
	transition-property: left, top;
}

.react-grid-item.cssTransforms {
	transition-property: transform;
}

.react-grid-item.resizing {
	z-index: 1;
	will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
	transition: none;
	z-index: 3;
	will-change: transform;
}

.react-grid-item.react-grid-placeholder {
	background: teal;
	opacity: 0.2;
	transition-duration: 100ms;
	z-index: 2;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.react-grid-item>.react-resizable-handle {
	position: absolute;
	width: 20px;
	height: 20px;
	bottom: 0;
	right: 0;
	z-index: 9999;
	cursor: se-resize;
}

.react-grid-item>.react-resizable-handle::after {
	content: "";
	position: absolute;
	right: 3px;
	bottom: 3px;
	width: 5px;
	height: 5px;
	border-right: 2px solid rgba(0, 0, 0, 0.4);
	border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.gauge-container {
	width: 300px;
	height: 175px;
	display: block;
	float: left;
	padding: 10px;
	/* background-color: ; */
	margin: 7px;
	border-radius: 3px;
	position: relative;
}

.gauge-container>.gauge>.dial {
	stroke: #ccc;
	stroke-width: 13;
}

.gauge-container>.gauge>.value {
	stroke: #278881;
	stroke-dasharray: none;
	stroke-width: 13;
}

.gauge-container>.gauge>.value-text {
	fill: #000;
	font-weight: 100;
	font-size: 1em;
}

.leaflet-touch .leaflet-bar {
	border: none!important;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65)!important;
}

.custom .leaflet-popup-tip, .custom .leaflet-popup-content-wrapper {
	background: #ffffff;
	color: #000;
	border-radius: 3px;
}

.customDark .leaflet-popup-tip, .customDark .leaflet-popup-content-wrapper {
	background: #424242;
	color: #ffffff;
	border-radius: 3px;
}

html * {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	/* padding: 0px !important;
	overflow: auto !important; */
	/* background-color: #EEEEEE;
	color: #3C4858; */
	margin: 0;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	font-weight: 300;
	line-height: 1.5em;
}

blockquote footer:before, blockquote small:before {
	content: '\2014 \00A0';
}

body {
	background-color: #EEEEEE;
	color: #3C4858;
}

blockquote p {
	font-style: italic;
}

body, h1, h2, h3, h4, h5, h6 {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 300;
	line-height: 1.5em;
}

/* a {
    color: #37a891;
    text-decoration: none;
}

a:hover, a:focus {
    color: #37a891;
    text-decoration: none;
} */

legend {
	border-bottom: 0;
}

* {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	-webkit-tap-highlight-color: transparent;
}

*:focus {
	outline: 0;
}

a:focus, a:active, button:active, button:focus, button:hover, button::-moz-focus-inner, input[type="reset"]::-moz-focus-inner, input[type="button"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner, select::-moz-focus-inner, input[type="file"]>input[type="button"]::-moz-focus-inner {
	outline: 0 !important;
}

legend {
	margin-bottom: 20px;
	font-size: 21px;
}

output {
	padding-top: 8px;
	font-size: 14px;
	line-height: 1.42857;
}

label {
	font-size: 14px;
	line-height: 1.42857;
	color: #AAAAAA;
	font-weight: 400;
}

footer {
	padding: 15px 0;
}

footer ul {
	margin-bottom: 0;
	padding: 0;
	list-style: none;
}

footer ul li {
	display: inline-block;
}

footer ul li a {
	color: inherit;
	padding: 15px;
	font-weight: 500;
	font-size: 12px;
	text-transform: uppercase;
	border-radius: 3px;
	text-decoration: none;
	position: relative;
	display: block;
}

footer ul li a:hover {
	text-decoration: none;
}

@media (max-width: 991px) {
	#bodyClick {
		height: 100%;
		width: 100%;
		position: fixed;
		opacity: 0;
		top: 0;
		left: auto;
		right: 260px;
		content: "";
		z-index: 9999;
		overflow-x: hidden;
	}
}